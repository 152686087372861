@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');
    }
}

/* Get a full 100% background */
/* html, body, #root {
    height: 100%;
    display: flex;
    flex-direction: column;
} */

[data-reactroot] 
        {height: 100% !important; }

/* @media (prefers-color-scheme: dark) {
    html, body, #root {
        background-color: theme('colors.gray.800');
    }
} */